import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Navigator } from '../../LanguageHelpers/Navigator';

const Button = styled.div`
  display: flex;
  flex-flow: row wrap;
  column-gap: var(--gapL);
  row-gap: var(--gapRegular);
  width: 100%;
  max-width: var(--globalContainer);
  grid-gep: var(--gapSmall);
  justify-content: ${props => props?.justifyContent || "flex-start"};
`;

export const Buttons = ({
    button,
    buttonLinks,
    justifyContent,
}) => (
    <Button justifyContent={justifyContent}>
        <Fragment>
            {buttonLinks && buttonLinks.map(({ buttonName, buttonLink }) => (
                <Navigator
                    className="classicButton classicButtonOutline"
                    recordId={buttonLink?.id}
                    key={buttonLink?.id}
                >
                    {buttonName}
                </Navigator>
            ))}
        </Fragment>
        <Fragment>
            {button &&
                <Button justifyContent={justifyContent}>{button}</Button>
            }
        </Fragment>
    </Button>
);
